[data-src] {

  opacity: 0;
  transition:
    filter ($trans-speed * 2) $trans-ease,
    opacity ($trans-speed * 2) $trans-ease;

  &.lazyloaded {

    opacity: 1;

  }

}