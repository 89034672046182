.c-moreprojects {
	// $root: &;
	
	// @extend %gutters-all;
	width: 100%;
	background-color: #F3F3F3;

	&__container {
		padding: 0 30px 30px;
	}


	@include media('x-large') {
		font-size: map-get($type, '10');
	}

	@include media('xx-large') {
		font-size: map-get($type, '9');
	}
	p {
		margin: 0 0 20px 0;
	}
	a {
		display: block;
		opacity: 1;
		color: map-get($brand, 'dark');
		&:hover {
			img {
				transform: scale(1.1);
			}
		}
	}
	img {
		width: 100%;
		transition: 0.3s ease-in-out;
		display: block;
	}
	.media {
		margin-bottom: 25px;
		figure {
			margin: 0;
			overflow: hidden;
			display: block;
		}
	}
	&__hero {
		.c-moreprojects-title {
			margin: 0;
		}
		// @extend .m-text--title;
		// font-family: $font-headings;
		// @include media('medium') {
		// 	text-align: center;
		// 	margin-bottom: 35px;
		// }

		padding: 35px 0 30px 0;
		@extend .m-text--title;
		font-family: $font-headings;
		&--title {
			margin: 0;
		}
		@include media('medium') {
			text-align: center;
			padding: 51px 30px;
		}
	}
	&__item {
		margin-bottom: 25px;
		@include media('medium') {
			width: 50%;
			padding: 0 24px;
		}
	}
	&__list {
		@include media('medium') {
			display: flex;
			max-width: 579px;
			margin-left: auto;
			margin-right: auto;
		}
		@include media('x-large') {
			max-width: 804px;
		}
	}
	&__item-caption {
		color: map-get($brand, 'dark');
		margin: 0;
		@extend .m-text--copy;
		p {
			color: map-get($brand, 'dark');
			margin-bottom: 0;
			opacity: 1;
		}
	}
	&__item-title {
		line-height: 1.3;
		@extend .m-text--copy;
		font-family: $font-headings;
		color: map-get($brand, 'dark');
		margin: 0;
	}
	

	&__3 {
		background-color: #fff;
		@include media('medium') {
			margin-bottom: 90px;
		}
		.c-moreprojects__container {
			padding: 35px 30px 30px;
			@include media('large') {
				padding-left: 78px;
				padding-right: 78px;
			}
		}
		.c-moreprojects__list {
			max-width: none;
		}
		.c-moreprojects__item {
			@include media('medium') {
			padding: 0 12px;
			}
		}
	}
}

