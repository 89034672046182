.c-footer {
	$root: &;
	@extend %gutters-all;
	padding-left: 30px;
	padding-right: 30px;
	width: 100%;
	color: white;
	background-color: map-get($brand, 'black');

	@include media('x-large') {
		font-size: map-get($type, '10');
	}

	@include media('xx-large') {
		font-size: map-get($type, '9');
	}

	@include media('large') {
		padding-left: 90px;
		padding-right: 90px;
	}
	@include media('x-large') {
		padding-left: 130px;
		padding-right: 130px;
	}
	p {
		margin: 0 0 20px 0;
		&:last-child {
			margin-bottom: 0;
		}
	}
	a {
		color: #9E9D9D;
		text-decoration: underline;
		&:hover {
			color: white;
		}
	}
	&__top {
		font-size: 21px;
		padding-top: 15px;
		margin-bottom: 80px;
		@include media('medium') {
			display: flex;
			padding-top: 60px;
		}
		@include media('large') {
			font-size: 23px;
			height: 220px;
			margin-bottom: 0;
		}
		@include media('x-large') {
			height: 264px;
		}
		p {
			max-width: 535px;
		}
		.c-footer-title {
			font-size: 27px;
			margin: 0;
			padding: 0;
			margin-bottom: 35px;
			font-family: $font-headings;
			letter-spacing: -0.02em;
			@include media('large') {
				font-size: 31px;
			}
		}
	}
	&__container {
		position: relative;
	}
	&__bottom {
		font-size: 17px;
		margin-bottom: 60px;
		@include media('medium') {
			display: flex;
			justify-content: flex-end;
			font-size: 15px;
			margin-bottom: 0;
		}
		@include media('large') {
			font-size: 19px;
		}
		h3,h4,h5,h6 {
			font-size: inherit;
			line-height: 1.3;
			margin: 0;
			padding: 0;
			font-family: $font-headings;
		}
	}
	&__copyright {
		font-size: 11px;
		@include media('medium') {
			position: absolute;
			bottom: 0;
			left: 0;
			display: block;
			height: auto;
		}
		@include media('large') {
			font-size: 15px;
		} 
	}
	&__col {
		@include media('medium') {
			width: 50%;
		}
	}
}
