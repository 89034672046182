.c-menu {
  position: fixed;
  z-index: map-get($depth, 'menu');
  top: 0;
  // background-color: #fff;
  width: 100%;
  display: none;
  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: map-get($brand, 'black');
    opacity: 0.9;
  }
  &__content {
    opacity: 0;
    position: relative;
    z-index: 1;
    color: #fff;
    font-size: 20px;
    line-height: 1.5;
    ul {
      padding: 0;
      margin: 0;
      margin-top: 50px;
      display: block;
    }
    li {
      margin: 0;
      padding: 0;
      display: block;
      &:before {
        display: none;
      }
    }
    a {
      display: inline-block;
      color: #fff;
      font-family: $font-headings;
      font-size: 30px;
      padding: 0;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  &__container {
    padding: 78px 30px;
    @include media('medium') {
      padding: 130px 90px;
    }
    @include media('x-large') {
      padding: 130px;
    }
  }
}

body.has-navigation {
  .c-menu {
    display: block;
    &__content {
      transition: opacity 0.3s ease-in-out;
      opacity: 1;
    }
  }
}