@font-face {
    font-family: 'archiabold';
    src: url('../../static/fonts/archia-bold-webfont.eot');
    src: url('../../static/fonts/archia-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../../static/fonts/archia-bold-webfont.woff2') format('woff2'),
         url('../../static/fonts/archia-bold-webfont.woff') format('woff'),
         url('../../static/fonts/archia-bold-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'archiasemibold';
    src: url('../../static/fonts/archia-semibold-webfont.eot');
    src: url('../../static/fonts/archia-semibold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../../static/fonts/archia-semibold-webfont.woff2') format('woff2'),
         url('../../static/fonts/archia-semibold-webfont.woff') format('woff'),
         url('../../static/fonts/archia-semibold-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'archiaregular';
    src: url('../../static/fonts/archia-regular-webfont.eot');
    src: url('../../static/fonts/archia-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../../static/fonts/archia-regular-webfont.woff2') format('woff2'),
         url('../../static/fonts/archia-regular-webfont.woff') format('woff'),
         url('../../static/fonts/archia-regular-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

// @font-face {
//   font-family: 'MultiAdaptor';
//   src: url('../../static/fonts/Montserrat-Light.ttf') format('truetype');
//   font-weight: 300;
//   font-style: normal;
// }

// @font-face {
//   font-family: 'MultiAdaptor';
//   src: url('../../static/fonts/Montserrat-Regular.ttf') format('truetype');
//   font-weight: 400;
//   font-style: normal;
// }

// @font-face {
//   font-family: 'MultiAdaptor';
//   src: url('../../static/fonts/Montserrat-SemiBold.ttf') format('truetype');
//   font-weight: 600;
//   font-style: normal;
// }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
  margin: 20px 0;
  line-height: 1;
}

h1,
.t-h1 {
  @include type-style("h1");
}

h2,
.t-h2 {
  @include type-style("h2");
}

h3,
.t-h3 {
  @include type-style("h3");
}

h4,
.t-h4 {
  @include type-style("h4");
}

h5,
.t-h5 {
  @include type-style("h5");
}

h6,
.t-h6 {
  @include type-style("h6");
}

strong,
b {
  font-weight: 600;
}

a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  color: map-get($brand, 'grey');
  cursor: pointer;
  text-decoration: underline;
  text-decoration-color: rgba(0, 0, 0, 0);
  padding: 0;
  transition:
    color $trans-speed $trans-ease,
    text-decoration-color $trans-speed $trans-ease;

  &:hover,
  &:active,
  &:focus {
    color: #000;
  }
}

p,
ul,
ol,
li,
address {
  margin: 20px 0;
}

address {
  font-style: normal;
}

ul,
ol {
  padding: 0 0 0 20px;
  margin: 20px 0;
  list-style: none;

  li {
    margin: 20px 0;
    position: relative;

    &::before {
      position: absolute;
      top: 8px;
      left: -20px;
      content: "";
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: #000;
    }
  }
}

blockquote,
cite {
  font-style: normal;
  padding: 0;
  display: block;
}

blockquote {
  margin: 0;
  quotes: "\201C""\201D""\2018""\2019";
  
  @include media(map-get($bp, 'medium')) {
    padding: 0 0 0 30px;
  }

  > p {

    position: relative;

    &::before,
    &::after {

      color: inherit;
      font-size: inherit;
      
      @include media(map-get($bp, 'medium')) {
        position: absolute;
      }

    }

    &::before {
      content: open-quote;

      @include media(map-get($bp, 'medium')) {
        left: -30px;
      }

    }

    &::after {
      content: close-quote;
    }
  }
}
