.c-header {
  $root: &;

  @extend %gutters-horizontal;
  padding-right: 30px;
  position: fixed;
  z-index: map-get($depth, 'header');
  top: 9px;
  // background-color: #fff;
  width: 100%;
  height: map-get($header, 'small');
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: transform ($trans-speed * 2) $trans-ease;

  .is-scrolling-down.has-scrolled-down & {
    transform: translate3d(0, -100%, 0);
  }

  @include media('medium') {
    top: 0;
    height: map-get($header, 'medium');
    padding-right: 40px;
  }
  @include media('xx-large') {
    height: map-get($header, 'large');
  }

  &__hamburger {
    width: 60px;
    height: 100%;
    margin-left: -10px;

    @include media('medium') {
      margin-left: -40px;
    }
  }

  &__logo {
    margin-left: auto;
    z-index: map-get($depth, 'navigation') + 1;
  }
}

.home {
  .c-sitename {
    display: block;
  }
}
.c-sitename {
  display: none;
  position: absolute;
  top: 14px;
  left: 85px;
  right: 85px;
  text-align: center;
  // z-index: map-get($depth, 'navigation') + 1;
  z-index: map-get($depth, 'header');
  font-family: $font-headings;
  font-weight: normal;
  font-size: 21px;
  text-decoration: none;
  color: map-get($brand, 'white');
  padding-top: 10px;
  @include media('medium') {
    top: 0;
    font-size: 27px;
    text-align: left;
    padding-top: 40px;
    right: auto;
  }
  @include media('xx-large') {
    left: 105px;
    padding-top: 64px;
  }
  // a {
  //   font-family: $font-headings;
  //   display: block;
  //   padding: 10px 0;
  //   text-decoration: none;
  //   color: map-get($brand, 'white');

  //   @include media('medium') {
  //     padding: 0;
  //   }

  //   &:hover,
  //   &:active,
  //   &:focus {
  //     text-decoration: underline;
  //     color: map-get($brand, 'white');
  //   }
  // }
}

body.has-navigation {
  .c-header {
    z-index: map-get($depth, 'header-open');
  }
  .hamburger.is-active .hamburger-inner, .hamburger.is-active .hamburger-inner::before, .hamburger.is-active .hamburger-inner::after {
    background-color: map-get($brand, 'white');
  }
}
