$hamburger-padding-x                       : 0px;
$hamburger-padding-y                       : 0px;
$hamburger-layer-width                     : 20px;
$hamburger-layer-height                    : 2px;
$hamburger-layer-spacing                   : 4px;
$hamburger-layer-color                     : map-get($brand, 'dark');
$hamburger-layer-border-radius             : 0px;
$hamburger-hover-opacity                   : 1;
$hamburger-hover-transition-duration       : $trans-speed;
$hamburger-hover-transition-timing-function: $trans-ease;
$hamburger-types: (
  squeeze
);

.hamburger {

  $root: &;

  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  padding: 0;
  outline: none;

  &:hover,
  &:active,
  &:focus {
    opacity: 1;
  }

  &.is-active {
    &:hover,
    &:active,
    &:focus {
      opacity: 1;
    }
  }

  &-box {
    width: 20px;
    height: 15px;
  }

  &-inner {
    &,
    &::before,
    &::after {
      border-radius: 0;
      width: 100%;
      height: 2px;
      background-color: map-get($brand, 'dark');
      transition: all $trans-speed $trans-ease;
    }

    &::before {
      top: -6px;
    }

    &::after {
      bottom: -6px;
    }
  }
}
