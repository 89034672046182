[data-inview] {
  opacity: 0;
  transform: translate3d(0, 60px, 0);
  transition: opacity ($trans-speed * 6) ($trans-speed) $easeOutExpo, transform ($trans-speed * 6) ($trans-speed) $easeOutExpo;
  &.fade-only {
    transform: translate3d(0, 0, 0);
  }
  &.in-view {

    &-visible {

      opacity: 1;
      transform:
      translate3d(0, 0, 0);
    }
  }
}
.fade-only {
  transform: translate3d(0, 0, 0);
}
.fade-only-no-transition {
  transform: translate3d(0, 0, 0);
  transition: 0.3s;
  transition-delay: 0.5s;
}
