.c-clients {
	width: 100%;
	color: white;
	background-color: map-get($brand, 'black');

	p {
		margin: 0 0 20px 0;
	}
	a {
		display: block;
		opacity: 1;
		color: map-get($brand, 'dark');
		&:hover {
			img {
				transform: scale(1.1);
			}
		}
	}
	img {
		width: 100%;
		transition: 0.5s ease-in-out;
		display: block;
	}
	.media {
		margin-bottom: 25px;
		figure {
			margin: 0;
			overflow: hidden;
			display: block;
		}
	}
	&__hero {
		padding: 35px 30px 30px 30px;
		@extend .m-text--title;
		font-family: $font-headings;
		&--title {
			margin: 0;
		}
		@include media('medium') {
			text-align: center;
			padding: 51px 30px;
		}
	}
	&__image {
		padding: 5px 30px;
		margin-bottom: 25px;
		@include media('medium') {
			padding: 0;
			width: 66.66%;
			margin-bottom: 90px;
		}
	}
	&__copy {
		@extend .m-text--copy;
		&--inner {
			padding: 0 30px;
			@include media('medium') {
				padding: 0 35px 0 0;
			}
			@include media('large') {
				padding: 0 55px 0 0;
			}
		}

		strong {
			font-family: $font-headings;
		}
		@include media('medium') {
			width: 33.33%;
		}
	}
	&__clientcontent {
		width: 100%;
		overflow: hidden;
		@include media('medium') {
			display: flex;
			padding: 0 60px;
		}
		@include media('large') {
			padding: 0 90px;
		}

	}
}
