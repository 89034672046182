.c-logo {
  $root: &;

  width: 25px;
  height: 43px;

  @include media('medium') {
    width: 33px;
    height: 58px;
  }

  @include media('xx-large') {
    width: 48px;
    height: 84px;
  }

  svg {
    fill: map-get($brand, 'dark');
  }
}

body.has-navigation {
  svg {
    fill: map-get($brand, 'white');
  }
}