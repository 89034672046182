%gutters-horizontal {
  @include gutters-horizontal;
}

%gutters-vertical {
  @include gutters-vertical;
}

%gutters-all {
  @include gutters-all;
}

%a11y,
.a11y {

  position: absolute; 
  overflow: hidden; 
  clip: rect(0 0 0 0); 
  height: 1px;
  width: 1px; 
  margin: -1px;
  padding: 0;
  border: 0;
  pointer-events: none;
  color: rgba(255, 255, 255, 0);

}

%list-reset {
  margin: 0;
  padding: 0;
  list-style: none;

  li {

    &::before {

      display: none;

    }

  }
}

%caps-style {

  font-family: $font-headings;
  text-transform: uppercase;
  letter-spacing: 0.03em;

}

%button {
  $root: &;

  @include type-style("h7");
  
  display: inline-flex;
  width: auto;
  margin: 0;
  padding: 8px 20px;
  font-weight: 600;
  cursor: pointer;
  user-select: none;
  text-decoration: none;
  text-align: center;
  background-color: #fff;
  color: map-get($brand, 'blue');
  border: none;
  border-radius: 30px;
  vertical-align: middle;
  appearance: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  backface-visibility: hidden;
  outline: none;
  transform:
    translate3d(0, 0, 0);
  transition:
    background-color $trans-speed $trans-ease,
    color $trans-speed $trans-ease;

  @include media(map-get($bp, 'x-large')) {

    @include type-style("h6");

    padding: 12px 30px;

  }

  &:hover,
  &:active,
  &:focus {

    background-color: map-get($brand, 'blue');
    color: #fff;

  }
}
