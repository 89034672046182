.m-panels {
  $root: &;

  width: 100%;

  @include media('large') {
    position: relative;
  }

  &__intro {
    @extend %gutters-all;

    width: 100%;
    padding-top: 60px;
    padding-bottom: 0;
    display: flex;

    @include media('large') {
      padding-top: 85px;
      flex-direction: column;
      width: 50%;
      position: absolute;
      top: -90px;
      left: 0;
    }
  }

  &__main-title {
    text-transform: uppercase;
    color: map-get($brand, 'dark');
    font-size: map-get($type, '14');

    @include media('x-large') {
      font-size: map-get($type, '10');
    }
  }

  &__main,
  &__aside {
    font-size: map-get($type, '13');

    @include media('large') {
      width: 100%;
    }

    > * {
      &:first-child {
        @include media('large-max') {
          margin-top: 0;
        }
      }

      &:last-child {
        @include media('large-max') {
          margin-bottom: 0;
        }
      }
    }
  }

  &__main {
    max-width: 550px;

    @include media('large-max') {
      flex: 1;
      padding-right: 10px;
    }

    @include media('medium') {
      font-size: map-get($type, '9');
    }
  }

  &__aside {
    width: 43.5%;

    @include media('large-max') {
      padding-left: 10px;
      border-left: 1px solid map-get($brand, 'grey');
    }

    @include media('x-large') {
      font-size: map-get($type, '10');
    }

    @include media('xx-large') {
      font-size: map-get($type, '9');
    }
  }

  &__item {
    width: 100%;
    display: flex;
    align-items: center;
    margin: 80px 0;

    @include media('large') {
      margin: 120px 0;
    }

    @include media('xx-large') {
      margin: 170px 0;
    }

    &--has-gutters {
      &#{$root}__item--align-left {
        padding-left: 80px;

        @include media('large') {
          padding-left: 120px; 
        }
    
        @include media('xx-large') {
          padding-left: 170px; 
        }
      }
      &#{$root}__item--align-right {
        padding-right: 80px;

        @include media('large') {
          padding-right: 120px; 
        }
    
        @include media('xx-large') {
          padding-right: 170px; 
        }
      }
    }

    &--align-left {
      justify-content: flex-start;
    }

    &--align-right {
      justify-content: flex-end;
    }
  }

  &__content {
    position: relative;
    color: map-get($brand, 'dark');
    text-decoration: none;

    #{$root}__item--size-small & {
      width: 37%;
    }

    #{$root}__item--size-medium & {
      width: 44%;
    }

    #{$root}__item--size-large & {
      width: 63%;
    }

    &:hover,
    &:active,
    &:focus {
      color: map-get($brand, 'dark');
    }
  }

  &__media {
    width: 100%;
    display: block;
  }

  &__info {
    text-transform: uppercase;
    position: absolute;
    z-index: 1;
    bottom: -2px;

    #{$root}__item--align-left & {
      right: 0;
    }

    #{$root}__item--align-right & {
      left: 0;
    }

    #{$root}__item--size-small#{$root}__item--align-left & {
      transform: translate3d(calc(100% - 30px), 0, 0);

      @include media('large') {
        transform: translate3d(calc(100% - 60px), 0, 0);
      }
    }

    #{$root}__item--size-small#{$root}__item--align-right & {
      transform: translate3d(calc(-100% + 30px), 0, 0);

      @include media('large') {
        transform: translate3d(calc(-100% + 120px), 0, 0);
      }
    }

    #{$root}__item--size-medium#{$root}__item--align-left & {
      transform: translate3d(calc(100% - 30px), 0, 0);

      @include media('large') {
        transform: translate3d(calc(100% - 60px), 0, 0);
      }
    }
    #{$root}__item--size-medium#{$root}__item--align-right & {
      transform: translate3d(calc(-100% + 30px), 0, 0);

      @include media('large') {
        transform: translate3d(calc(-100% + 60px), 0, 0);
      }
    }

    #{$root}__item--size-large#{$root}__item--align-left & {
      transform: translate3d(calc(100% - 30px), 0, 0);

      @include media('large') {
        transform: translate3d(calc(100% - 60px), 0, 0);
      }
    }
    #{$root}__item--size-large#{$root}__item--align-right & {
      transform: translate3d(calc(-100% + 30px), 0, 0);

      @include media('large') {
        transform: translate3d(calc(-100% + 60px), 0, 0);
      }
    }

    @include media('large') {
      bottom: -9px;
    }
  }

  &__title {
    margin: 0;
    padding: 0;
    font-weight: 600;
    font-size: map-get($type, '14');

    @include media('large') {
      font-size: map-get($type, '10');
    }

    @include media('xx-large') {
      font-size: map-get($type, '9');
    }
  }

  &__headline {
    margin: 0;
    padding: 0;
    font-size: map-get($type, '10');

    @include media('large') {
      font-size: 50px;
    }

    @include media('xx-large') {
      font-size: map-get($type, '3');
    }
  }

  &__arrow {
    vertical-align: middle;

    svg {
      width: 21px;
      height: 21px;
      margin: 0 0 -1px 0;

      @include media('large') {
        width: 45px;
        height: 45px;
      }
    }
  }
}
