.m-overview {
  $root: &;

  width: 100%;
  font-size: 17px;
  @include media('medium') {
    font-size: 15px;
  }
  @include media('x-large') {
    font-size: 19px;
  }
  strong, h1, h2, h3, h4 {
    line-height: 1.3;
    font-family: $font-headings;
  }
  p {
    margin: 0 0 20px 0;
    &:last-child {
      margin-bottom: 0;
    }
  }
  h1, h2, h3, h4 {
    margin: 0;
    &:after {
      // add space but working with forced line break in the content
      content: '';
      height: 1em;
      width: 100%;
      display: block;
    }
    font-size: 21px;
    @include media('x-large') {
      font-size: 25px;
    }
  }
  h1 {
    font-size: 27px;
    letter-spacing: -0.05em;
    @include media('large') {
      letter-spacing: -0.02em;
    }
    @include media('medium') {
      padding-right: 30px;
      position: relative;
      top: -6px;
    }
    @include media('x-large') {
      font-size: 38px;
      top: -11px;
    }
  }

  &__area-a {
    width: 100%;
    padding: 25px 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @include media('medium') {
      padding-top: 50px;
      padding-bottom: 50px;
      flex-direction: row;
    }
    @include media('large') {
      padding: 90px 90px 60px 90px;
    }
    @include media('x-large') {
      padding: 120px 130px 120px 130px;
    }
  }

  &__item-a {
    @include media('medium') {
      width: 50%;
      order: 1;
      padding-right: 30px;
    }
    @include media('large') {
      padding-right: 90px;
    }
    @include media('x-large') {
      padding-left: 0px;
    }
  }

  &__item-b {
    font-size: 21px;
    @include media('x-large') {
      font-size: 25px;
    }
    @include media('medium') {
      order: 2;
      width: 50%;
    }
  }

  &__area-b {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: map-get($brand, 'grey-light');

    @include media('medium') {
      display: grid;
      grid-template-columns: calc(50% - 45px) 1fr;
      grid-template-rows: max-content auto;
      padding: 0 0 0 90px;
      background-color: transparent;
    }
    @include media('large') {

    }
    @include media('x-large') {
      grid-template-columns: calc(50% - 65px) 1fr;
      padding: 0 0 0 130px;
    }
  }

  &__media-a {
    position: relative;
    padding: 0 0 0 60px;
    width: 100%;
    @include media('medium') {
      width: 50vw;
      grid-column: 2 / 3;
      padding: 0;
      background-color: map-get($brand, 'grey-light');
      overflow: hidden; 
    }

    &::before {
      content: "";
      width: 100%;
      height: 40px;
      background-color: white;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;

      @include media('medium') {
        display: none;
      }
    }

    // * {
    //   position: relative;
    //   margin: 0;
    //   padding: 0; 
    //   z-index: 1;
    // }
  }

  &__media-b {
    padding-left: 60px;
    @include media('medium') {
      padding-left: 0;
      grid-column: 2 / 3;
      overflow-x: hidden;
      background-color: map-get($brand, 'grey-light');
    }
  }

  &__item-c {
    padding: 25px 60px;
    background-color: map-get($brand, 'grey-light');
    @include media('medium') {
      padding: 30px 30px;
      padding-top: 100px;
      grid-column: 1 / 2;
      grid-row: 1 / -1;
      position: relative;
    }
    @include media('large') {
      padding: 160px 70px 50px 70px;
    }
    @include media('x-large') {
      padding: 215px 100px 100px 100px;
    }

    &::before {
      @include media('medium') {
        content: "";
        width: 100%;
        height: 70px;
        background-color: white;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
      }
      @include media('large') {
        height: 120px;
      }
    }
  }

  &__area-c {
    width: 100%;
    position: relative;

    @include media('medium') {
      display: grid;
      grid-template-columns: calc(50% - 15px) 1fr;
      grid-template-rows: max-content auto;
      padding: 0 0 0 30px;
    }
    @include media('large') {
      grid-template-columns: calc(50% - 45px) 1fr;
      padding: 0 0 0 90px;
    }
    @include media('x-large') {
      grid-template-columns: calc(50% - 65px) 1fr;
      padding: 0 0 0 130px;
    }

    // &::after {
    //   width: 100%;
    //   height: 60px;
    //   position: absolute;
    //   bottom: 0;
    //   z-index: -1;
    //   left: 0;
    //   background-color: map-get($brand, 'grey-light');

    //   @include media('large') {
    //     content: "";
    //     height: 100px;
    //   }
    // }
  }

  &__item-d {
    padding: 25px 60px;
    @include media('medium') {
      padding-top: 30px;
      padding-bottom: 30px;
      padding-left: 30px;
      grid-column: 2 / 3;
      grid-row: 1 / -1;
    }
    @include media('large') {
      padding: 60px 140px 170px 70px;
    }
    @include media('x-large') {
      padding: 100px 190px 100px 100px;
    }
  }

  &__media-d {
    padding-left: 60px;

    @include media('medium') {
      padding-left: 60px;
    }
    @include media('large') {
      padding-left: 70px;
    }
    @include media('x-large') {
      padding-left: 100px;
    }
  }

  &__area-d {
    display: flex;
    flex-direction: column;
    background-color: map-get($brand, 'grey-light');

    @include media('medium') {
      flex-direction: row;
      align-items: flex-start;
      justify-content: center;
    }
  }

  &__media-e {
    padding-left: 60px;

    @include media('medium') {
      order: 1;
      padding: 0;
      width: 50%;
    }
  }

  &__item-e {
    padding: 25px 60px;
    @include media('medium') {
      padding-top: 30px;
      padding-bottom: 30px;
      padding-left: 90px;
      padding-right: 30px;
      width: 50%;
    }

    @include media('large') {
      padding: 70px 70px 70px 160px;
    }
    @include media('x-large') {
      padding: 70px 70px 70px 230px;
    }
  }
}
