::placeholder {
  color: lighten(map-get($brand, 'dark'), 20%);
}

::selection {
  background-color: rgba(map-get($brand, 'dark'), .99);
  color: #fff;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  // text-size-adjust: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  font-size: 1em;
  line-height: 1.3;
  min-height: 100%;
}

body {
  color: map-get($brand, 'dark');
  background-color: #fff;
  font-family: $font-sans;
  font-weight: 300;
  letter-spacing: -0.01em;

  &.has-navigation {
    overflow: hidden;

    @include media(map-get($bp, 'medium')) {
      overflow: auto;
    }
  }
}

img {
  user-select: none;
}

.hide-desktop {
  display: block !important;
  @include media('medium') {
    display: none !important;
  }
}

.hide-mobile {
  display: none !important;
  @include media('medium') {
    display: block !important;
  }
}

#section-1 {
  z-index: 115;
  position: relative;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  .container-c-header {
    position: absolute;
    clip: rect(0, auto, auto, 0);
    height: 100vh;
    width: 100%;
  }
  .c-media__image {
    object-fit: cover;
    width: 100%;
    height: 100vh;
  }
  .scroll {
    display: block;
    width: 60px;
    z-index: 10;
    height: 40px;
    position: absolute;
    left: 50%;
    margin-left: -30px;
    bottom: 35px;
    background-image: url('../../static/svg/scroll-icon.svg');
    background-size: 40px 20px;
    background-position: center;
    background-repeat: no-repeat;
    @include media('medium') {
      width: 60px;
      height: 30px;
    }
  }
}

[data-theme="white"] {
  .c-logo svg {
    fill: map-get($brand, 'white');
  }
  .c-sitename {
    color: map-get($brand, 'white'); 
  }
  .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
    background-color: map-get($brand, 'white');
  }
}
[data-theme="black"] {
  .c-logo svg {
    fill: map-get($brand, 'black');
  }
  .c-sitename {
    color: map-get($brand, 'black'); 
  }
  .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
    background-color: map-get($brand, 'black');
  }
}