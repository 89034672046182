.m-image {
  $root: &;

  &--gutters-small,
  &--gutters-large {
    margin: 65px 0;

    @include media('x-large') {
      margin: 130px 0;
    }

    @include media('xx-large') {
      margin: 170px 0;
    }
  }

  &--gutters-small {
    // padding-left: 30px;
    // padding-right: 30px;

    @include media('x-large') {
      padding-left: 130px;
      padding-right: 130px;
    }

    @include media('xx-large') {
      padding-left: 170px;
      padding-right: 170px;
    }
  }

  &--gutters-large {
    padding-left: 60px;
    padding-right: 60px;

    @include media('x-large') {
      padding-left: 240px;
      padding-right: 240px;
    }

    @include media('xx-large') {
      padding-left: 340px;
      padding-right: 340px;
    }
  }
}