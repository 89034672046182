.c-navigation {
  $root: &;

  width: 100%;
  padding-top: 28px;

  &__list {
    @extend %list-reset;

    display: flex;
    flex-direction: row;
  }

  &__item {
    margin-left: 25px;
    font-family: $font-headings;
    font-weight: bold;
  }
  //   width: 100%;
  //   border-top: 1px solid map-get($brand, 'grey');
  //   margin: 0;
  //   padding: 0;
  //   text-transform: uppercase;
  //   font-weight: 600;
  //   font-size: map-get($type, '12');

  //   @include media('medium') {
  //     width: auto;
  //     border: none;
  //     margin: 0 12px;
  //   }

  //   @include media('xx-large') {
  //     margin: 0 32px;
  //     font-size: map-get($type, '10');
  //   }

  //   &:first-child {
  //     @include media('medium') {
  //       margin-left: 0;
  //     }
  //   }

  //   &:last-child {
  //     border-bottom: 1px solid map-get($brand, 'grey');

  //     @include media('medium') {
  //       margin-right: 0;
  //       border: none;
  //     }
  //   }
  // }

  &__link {
    display: block;
    padding: 10px 0;
    text-decoration: none;
    color: map-get($brand, 'white');

    @include media('medium') {
      padding: 0;
    }

    #{$root}__item.current-menu-item & {
      color: map-get($brand, 'white');
      text-decoration: underline;
    }

    &:hover,
    &:active,
    &:focus {
      text-decoration: underline;
      color: map-get($brand, 'white');
    }
  }
}
