@mixin gutters-horizontal() {
  padding-left: map-get($gutters, 'small');
  padding-right: map-get($gutters, 'small');

  @include media('medium') {
    padding-left: map-get($gutters, 'medium');
    padding-right: map-get($gutters, 'medium');
  }

  @include media('xx-large') {
    padding-left: map-get($gutters, 'large');
    padding-right: map-get($gutters, 'large');
  }
}

@mixin gutters-vertical() {
  padding-top: map-get($gutters, 'small');
  padding-bottom: map-get($gutters, 'small');

  @include media('medium') {
    padding-top: map-get($gutters, 'medium');
    padding-bottom: map-get($gutters, 'medium');
  }

  @include media('xx-large') {
    padding-top: map-get($gutters, 'large');
    padding-bottom: map-get($gutters, 'large');
  }
}

@mixin gutters-all() {
  padding: map-get($gutters, 'small');

  @include media('medium') {
    padding: map-get($gutters, 'medium');
  }

  @include media('xx-large') {
    padding: map-get($gutters, 'large');
  }
}

@mixin media($size) {
  $query: map-get($bp, $size);
  @media only screen and ($query) {
    @content;
  }
}

@mixin type-style($style: "body") {
  @if $style == "h1" {
    font-size: map-get($type, '1');
  }
  @else if $style == "h2" {
    font-size: map-get($type, '2');
  }
  @else if $style == "h3" {
    font-size: map-get($type, '3');
  }
  @else if $style == "h4" {
    font-size: map-get($type, '4');
  }
  @else if $style == "h5" {
    font-size: map-get($type, '5');
  }
  @else if $style == "h6" {
    font-size: map-get($type, '6');
  }
  @else if $style == "h7" {
    font-size: map-get($type, '7');
  }
  @else if $style == "h8" {
    font-size: map-get($type, '8');
  }
}
