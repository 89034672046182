.m-video {
  $root: &;

  &--gutters-small,
  &--gutters-large {
    margin: 65px 0;

    @include media('x-large') {
      margin: 120px 0;
    }

    @include media('xx-large') {
      margin: 170px 0;
    }
  }

  &--gutters-small {
    // padding-left: 30px;
    // padding-right: 30px;

    @include media('x-large') {
      padding-left: 130px;
      padding-right: 130px;
    }

    @include media('xx-large') {
      padding-left: 170px;
      padding-right: 170px;
    }
  }

  &--gutters-large {
    padding-left: 60px;
    padding-right: 60px;

    @include media('x-large') {
      padding-left: 240px;
      padding-right: 240px;
    }

    @include media('xx-large') {
      padding-left: 340px;
      padding-right: 340px;
    }
  }
}


/* Video background */

.videobg {
  top:0; 
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  width: 100%; /* Set video container element width here */
  height: 100%; /* Set video container element height here */
  overflow: hidden;
}

/* horizontally center the video */
.videobg-width {
  position: absolute;
  width: 100%; /* Change width value to cover more area*/
  height: 100%;
  left: -9999px;
  right: -9999px;
  margin: auto;
}

/* set video aspect ratio and vertically center */
.videobg-aspect {
  position: absolute;
  width: 100%;
  height: 0;
  top: -9999px;
  bottom: -9999px;
  margin: auto;
  padding-bottom: 56.25%; /* 16:9 ratio */
  overflow: hidden;
  
}

.videobg-make-height {
  position: absolute;
  top: 0; right: 0; bottom: 0; left: 0;
}

.videobg-hide-controls {
  box-sizing: content-box;
  position: relative;
  height: 100%;
  width: 100%;
  /* Vimeo timeline and play button are ~55px high */
  padding: 55px 97.7777px; /* 16:9 ratio */
  top: -55px; 
  left: -97.7777px; /* 16:9 ratio */
}

.videobg iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 0 none;
}
