:root {
  --app-height: 100vh;
}

$depth: (
  'menu': 130,
  'header': 100,
  'header-open': 131,
  'navigation': 200,
  'hamburger': 300
);

$sizes: (
  'small': 576px,
  'medium': 768px,
  'large': 992px,
  'x-large': 1200px,
  'xx-large': 2400px
);

$bp: (
  'small': 'min-width: #{map-get($sizes, 'small')}',
  'medium': 'min-width: #{map-get($sizes, 'medium')}',
  'large': 'min-width: #{map-get($sizes, 'large')}',
  'x-large': 'min-width: #{map-get($sizes, 'x-large')}',
  'xx-large': 'min-width: #{map-get($sizes, 'xx-large')}',
  'small-max': 'max-width: #{map-get($sizes, 'small') - 1px}',
  'medium-max': 'max-width: #{map-get($sizes, 'medium') - 1px}',
  'large-max': 'max-width: #{map-get($sizes, 'large') - 1px}',
  'x-large-max': 'max-width: #{map-get($sizes, 'x-large') - 1px}',
  'xx-large-max': 'max-width: #{map-get($sizes, 'xx-large') - 1px}'
);

$brand: (
  'white': #fff,
  'black': #121111,
  'dark': #373A3B,
  'grey': #959595,
  'grey-light': #f3f3f3
);

$header: (
  'small': 60px,
  'medium': 120px,
  'large': 170px
);

$type: (
  '1': 240px,
  '2': 170px,
  '3': 80px,
  '4': 60px,
  '5': 55px,
  '6': 40px,
  '7': 35px,
  '8': 30px,
  '9': 25px,
  '10': 20px,
  '11': 16px,
  '12': 15px,
  '13': 13px,
  '14': 12px
);

$gutters: (
  'small': 20px,
  'medium': 60px,
  'large': 85px
);

$font-headings: "archiasemibold", sans-serif;
$font-sans: "archiaregular", sans-serif;
$font-serif: "Libre Baskerville", serif;

$border-radius: 2px;

$easeInCubic: cubic-bezier(.55,.055,.675,.19);
$easeOutCubic: cubic-bezier(.215,.61,.355,1);
$easeInOutCubic: cubic-bezier(.645,.045,.355,1);
$easeInCirc: cubic-bezier(.6,.04,.98,.335);
$easeOutCirc: cubic-bezier(.075,.82,.165,1);
$easeInOutCirc: cubic-bezier(.785,.135,.15,.86);
$easeInExpo: cubic-bezier(.95,.05,.795,.035);
$easeOutExpo: cubic-bezier(.19,1,.22,1);
$easeInOutExpo: cubic-bezier(1,0,0,1);
$easeInQuad: cubic-bezier(.55,.085,.68,.53);
$easeOutQuad: cubic-bezier(.25,.46,.45,.94);
$easeInOutQuad: cubic-bezier(.455,.03,.515,.955);
$easeInQuart: cubic-bezier(.895,.03,.685,.22);
$easeOutQuart: cubic-bezier(.165,.84,.44,1);
$easeInOutQuart: cubic-bezier(.77,0,.175,1);
$easeInQuint: cubic-bezier(.755,.05,.855,.06);
$easeOutQuint: cubic-bezier(.23,1,.32,1);
$easeInOutQuint: cubic-bezier(.86,0,.07,1);
$easeInSine: cubic-bezier(.47,0,.745,.715);
$easeOutSine: cubic-bezier(.39,.575,.565,1);
$easeInOutSine: cubic-bezier(.445,.05,.55,.95);
$easeInBack: cubic-bezier(.6,-.28,.735,.045);
$easeOutBack: cubic-bezier(.175, .885,.32,1.275);
$easeInOutBack: cubic-bezier(.68,-.55,.265,1.55);
$authenticMotion: cubic-bezier(.4,0,.2,1);

$trans-speed: .25s;
$trans-delay: $trans-speed;
$trans-ease: $authenticMotion;
