.m-banner {
  $root: &;

  // @extend %gutters-horizontal;

  width: 100%;
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100vw;
  background-color: #000;
  // margin: 30px 0 0 0;
  // height: calc(100vh - #{map-get($header, 'small')} - #{map-get($gutters, 'small')} - 30px);
  // height: calc(var(--app-height) - #{map-get($header, 'small')} - #{map-get($gutters, 'small')} - 30px);

  // @include media('medium') {
  //   height: calc(100vh - #{map-get($header, 'medium')} - #{map-get($gutters, 'medium')});
  //   height: calc(var(--app-height) - #{map-get($header, 'medium')} - #{map-get($gutters, 'medium')});
  //   margin: 0;
  // }

  // @include media('xx-large') {
  //   height: calc(100vh - #{map-get($header, 'large')} - #{map-get($gutters, 'large')});
  //   height: calc(var(--app-height) - #{map-get($header, 'large')} - #{map-get($gutters, 'large')});
  // }

  .flickity-viewport,
  .flickity-slider,
  .c-slider__item {
    height: 100% !important;
  }

  &__container {
    // @extend %gutters-all;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    position: relative;
  }

  &__main {
    width: 100%;
    height: 100%;
  }

  // &__charge,
  // &__up,
  // &__your,
  // &__brand {
  //   position: absolute;
  //   z-index: 1;
  //   margin: 0;
  //   padding: 0;
  //   max-width: 550px;
  //   max-height: 550px;
  // }

  // &__charge {
  //   top: 0;
  //   left: 0;
  //   width: 46vw;
  //   transform:
  //     translate3d(0, calc(-50% + #{map-get($gutters, 'small')}), 0);

  //   @include media('medium') {
  //     transform:
  //       translate3d(0, calc(-50% + #{map-get($gutters, 'medium')}), 0);
  //   }
  
  //   @include media('xx-large') {
  //     transform:
  //       translate3d(0, calc(-50% + #{map-get($gutters, 'large')}), 0);
  //   }
  // }

  // &__up {
  //   display: block;
  //   top: 0;
  //   right: 0;
  //   width: auto;
  //   height: 46vw;
  //   transform:
  //     translate3d(calc(50% - #{map-get($gutters, 'small')}), 0, 0);

  //   @include media('medium') {
  //     transform:
  //       translate3d(calc(50% - #{map-get($gutters, 'medium')}), 0, 0);
  //   }
  
  //   @include media('xx-large') {
  //     transform:
  //       translate3d(calc(50% - #{map-get($gutters, 'large')}), 0, 0);
  //   }
  // }

  // &__your {
  //   bottom: 0;
  //   right: 0;
  //   width: 46vw;
  //   transform:
  //     translate3d(0, calc(50% - #{map-get($gutters, 'small')}), 0);

  //   @include media('medium') {
  //     transform:
  //       translate3d(0, calc(50% - #{map-get($gutters, 'medium')}), 0);
  //   }
  
  //   @include media('xx-large') {
  //     transform:
  //       translate3d(0, calc(50% - #{map-get($gutters, 'large')}), 0);
  //   }
  // }

  // &__brand {
  //   bottom: 0;
  //   left: 0;
  //   width: auto;
  //   height: 46vw;
  //   transform:
  //     translate3d(calc(-50% + #{map-get($gutters, 'small')}), 0, 0);

  //   @include media('medium') {
  //     transform:
  //       translate3d(calc(-50% + #{map-get($gutters, 'medium')}), 0, 0);
  //   }
  
  //   @include media('xx-large') {
  //     transform:
  //       translate3d(calc(-50% + #{map-get($gutters, 'large')}), 0, 0);
  //   }
  // }

  // &__statement {
  //   display: none;

  //   position: absolute;
  //   z-index: 1;
  //   top: 0;
  //   right: 0;
  //   bottom: 0;
  //   left: 0;
  //   text-transform: uppercase;
  //   font-weight: 600;
  //   margin: 0;
  //   padding: 0;
  //   user-select: none;
  //   pointer-events: none;

  //   span {
  //     position: absolute;
  //     width: 100%;
  //     font-size: map-get($type, '5');
  //     line-height: 1;

  //     @include media('medium') {
  //       font-size: map-get($type, '2');
  //     }

  //     &:nth-child(1) {
  //       top: -7px;
  //       left: -2px;

  //       @include media('medium') {
  //         top: -22px;
  //         left: -6px;
  //       }
  //     }

  //     &:nth-child(2) {
  //       top: -3px;
  //       left: calc(100% + 8px);
  //       transform-origin: top left;
  //       transform: rotate(90deg);

  //       @include media('medium') {
  //         top: -9px;
  //         left: calc(100% + 24px);
  //       }
  //     }

  //     &:nth-child(3) {
  //       bottom: 47px;
  //       left: 100%;
  //       transform-origin: bottom left;
  //       transform: rotate(180deg);

  //       @include media('medium') {
  //         bottom: 147px;
  //       }
  //     }

  //     &:nth-child(4) {
  //       bottom: -3px;
  //       left: 47px;
  //       transform-origin: bottom left;
  //       transform: rotate(-90deg);

  //       @include media('medium') {
  //         bottom: -10px;
  //         left: 147px;
  //       }
  //     }
  //   }
  // }
}
