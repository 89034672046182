.c-slider {
  width: 100%;
  height: 100%;
  max-width: 100vw;
  margin: 0;
  padding: 0;
  position: relative;
  &.c-slider__slideshow {
    @include media('medium') {
      max-width: 50vw;
    } 
  }
  &:after {
    content: "";
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
  }

  &__item {
    width: 100%;
    margin: 0;
    padding: 0;
    display: block !important;
  }

  img {
    display: block;
  }

  &__media,
  &__image {
    width: 100%;
    margin: 0;
    padding: 0;
  }
}