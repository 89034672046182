.m-text {
	$root: &;

	width: 100%;
	margin: 65px 0;
	padding-left: 60px;
	padding-right: 60px;
	
	font-size: 17px;
	@include media('large') {
		font-size: 19px;
	}
	@include media('x-large') {
		font-size: 23px;
	}
	strong, h2, h3, h4 {
		font-family: $font-headings;
	}
	h2, h3, h4 {
		margin-bottom: 1em;
		font-size: 21px;
		@include media('large') {
			font-size: 27px;
		}
		@include media('x-large') {
			font-size: 31px;
		}
	}

	@include media('medium') {
		padding-left: 130px;
		padding-right: 130px;
		max-width: auto;
		margin-left: auto;
		margin-right: auto;
	}
	@include media('large') {
		padding-left: 240px;
		padding-right: 240px;
	}

	@include media('x-large') {
		margin: 120px auto;
		// max-width: 780px;
		padding-left: 0;
		padding-right: 0;
		max-width: 780px;
	}

	@include media('xx-large') {
		margin: 170px auto;
	}

	// &__title {
	// 	text-transform: uppercase;
	// 	margin: 0;
	// 	padding: 0;
	// 	font-weight: 600;
	// 	font-size: map-get($type, '14');

	// 	@include media('x-large') {
	// 		font-size: map-get($type, '10');
	// 	}

	// 	@include media('xx-large') {
	// 		font-size: map-get($type, '9');
	// 	}
	// }

	&__content {
		margin: 0;
		padding: 0;
		// font-size: map-get($type, '11');

		// @include media('x-large') {
		// 	font-size: map-get($type, '8');
		// }

		// @include media('xx-large') {
		// 	font-size: map-get($type, '6');
		// }
	}
}

.m-text--title {
	font-family: $font-headings;
	font-size: 27px;
	letter-spacing: -0.05em;
	@include media('medium') {
		letter-spacing: -0.02em;
	}

	@include media('x-large') {
		font-size: 31px;
	}
}
.m-text--copy {
	font-size: 17px;
	@include media('medium') {
		font-size: 15px;
	}
	@include media('x-large') {
		font-size: 19px;
	}
}