.c-intro {
	width: 100%;

	&__container {
		padding: 35px 30px 0px;
		@include media('medium') {
			display: flex;
			padding: 50px 60px 25px 60px;
		}
		@include media('large') {
			padding: 90px 90px 25px 90px;
		}
		@include media('x-large') {
			padding: 120px 90px 25px 90px;
		}
	}

	p {
		margin: 0 0 20px 0;
	}
	a {
		text-decoration: underline;
		color: #9E9D9D;
		&:hover {
			color: inherit;
		}
	}
	img {
		width: 100%;
		transition: 0.5s ease-in-out;
		display: block;
	}
	.c-intro-title {
		margin: 0;
		padding: 0;
		margin-bottom: 25px;
		@extend .m-text--title;
		@include media('x-large') {
			font-size: 38px !important;
			position: relative;
    		top: -7px;
		}
	}
	&__col {
		p {
			font-size: 21px;
			@include media('large') {
				font-size: 25px;
			}
		}
		@include media('medium') {
			width: 50%;
		}
	}
}