.c-pullquote {
	$root: &;

	width: 100%;
	margin: 100px 0 85px;
	padding-left: 30px;
	padding-right: 30px;
	text-align: center;
	
	font-size: 17px;
	@include media('large') {
		font-size: 15px;
	}
	@include media('x-large') {
		font-size: 19px;
	}
	
	blockquote {
		margin: 0;
		padding: 0;
		p {
			font-family: $font-headings;
	  		font-weight: normal;
			margin-bottom: 23px;
			font-size: 27px;
			line-height: 31px;
			// @include media('large') {
			// 	font-size: 27px;
			// }
			@include media('x-large') {
				font-size: 38px;
				line-height: 42px;
				margin-bottom: 28px;
			}
		}
		cite {
			margin: 0;
			line-height: 1;
		}
	}

	@include media('medium') {
		padding-left: 120px;
		padding-right: 120px;
		margin: 105px auto 85px;
		max-width: auto;
		margin-left: auto;
		margin-right: auto;
	}
	@include media('large') {
		padding-left: 250px;
		padding-right: 250px;
		margin: 125px auto 100px;
	}

	@include media('x-large') {
		margin: 150px auto 128px;
		max-width: 780px;
		padding-left: 0;
		padding-right: 0;
	}

	@include media('xx-large') {

	}


	&__content {
		margin: 0;
		padding: 0;
	}
}