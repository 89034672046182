.c-media {
  $root: &;

  width: 100%;
  display: block;

  &--no-jank {
    height: 0;
    overflow: hidden;
    position: relative;
    // for mobile
    // padding-bottom: 100%;
    // @include media('medium') {
    //   padding-bottom: 56.25%;
    // }
  }

  &--fullscreen-mp4 {
    width: 100%;
    height: 100vh;
    position: relative;
    z-index: 1;

    video {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 100%;
      object-fit: cover;
      transform: translateX(-50%) translateY(-50%);
    }
  }

  &--fit {
    height: 100%;
  }

  &__iframe,
  &__image {
    display: block;
    width: 100%;

    #{$root}--no-jank & {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }

    #{$root}--fit & {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

  }
  &__iframe {
    position: relative;
    &:after {
      content: "";
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: block;
    }
  }
}